export const isPostcodeExcluded = (excludedPostcodes: Record<string, any>[], postcode: string) => {
    if (excludedPostcodes && postcode) {

        const checkPostcode = (postcodeToCheck: string) => {
            const matchingExcludedPostcodes = excludedPostcodes.filter((excludedPostcode: Record<string, any>) => {
                return (
                    excludedPostcode.elements.outbound_postcode.value === postcodeToCheck
                )
            })

            return matchingExcludedPostcodes.length > 0
        }

        // First, if we have a postcode with a space in it, assume the user has entered a valid postcode and check the outcode.
        const postcodeSegments = postcode.split(" ")

        if (postcodeSegments.length > 1 && checkPostcode(postcodeSegments[0].toUpperCase())) {
            return {
                excluded: true,
                outerPostcode: postcodeSegments[0],
            }
        }

        // No match so far, so remove any spaces and see if we have a valid postcode to check.
        let outerPostcode = postcode
        const postcodeCharacters = postcode.toUpperCase().replace(/\s+/g, "")

        if (postcodeCharacters.length >= 5) {
            // If 5 or more characters and no space (e.g. L12AB, WF61ZB, SW1A4BB etc.) check if the last two characters are alpha. If so,
            // remove the last three characters to get the outcode.
            if (postcodeCharacters.slice(-3).match("[0-9]{1}[A-Z]{2}")) {
                outerPostcode = postcodeCharacters.slice(0, postcodeCharacters.length - 3)
            }
        }

        if (outerPostcode !== "") {
            return {
                excluded: checkPostcode(outerPostcode),
                outerPostcode,
            }
        }
    }
    
    return { excluded: false }
}