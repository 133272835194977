import { useStaticQuery, graphql } from "gatsby"

export const useAllKontentPostcodeExclusionsData = () => {
  const { allKontentItemPostcodeExclusions } = useStaticQuery(
    graphql`
      query AllPostcodeExclusionsQuery {
        allKontentItemPostcodeExclusions {
          nodes {
            elements {
              outbound_postcode {
                value
              }
            }
            id
            system {
              name
            }
          }
        }
      }
    `
  )

  return allKontentItemPostcodeExclusions.nodes
}