import React from "react"
import { Button } from "../../../../../../button"
import { Modal } from "../../../../../../modal"
import { ICheckBox } from "../../../../../../../types/interfaces/ICheckBox"

export interface ConfirmationModalProps {
  customerId: string
  mode: string
  isOpen: boolean
  confirmContract: () => void
  closeModal: () => void
  isLoading: boolean
  checkBox?: ICheckBox
}

export const ConfirmationModal = ({ customerId, mode, isOpen, confirmContract, closeModal, isLoading, checkBox }: ConfirmationModalProps) => {
    let title = ""
    let description1 = ""
    let description2 = ""
    
    switch (mode) {
      case "cancel":
        title = "Cancel Changes"
        description1 = "Do you really want to cancel?"
        description2 = "The changes you made here will be lost."
        break
      case "confirm":
        title = "Confirm Contract"
        description1 = "Are you sure you want to confirm this contract?"
        description2 = ""
    }
    const stayHere = "Stay here"
    const cancelStr = "Cancel"
    const confirmStr = "Confirm"

    const newContractsPageUrl = `/cx-dashboard/customer/${customerId}/contracts/all-contracts`

    const handleOnClick = () => {
      if(mode === "confirm") {
        confirmContract()
      }
    }

    return (
      <Modal
        title={title}
        isOpen={isOpen}
        closeModal={closeModal}
      >
        <div className="mb-4">
          <p>{description1}</p>
          <p>{description2}</p>
        </div>
        <Button outline className="mr-3" onClick={closeModal}>
          {stayHere}
        </Button>
        <Button 
          id={mode === "confirm" ? "btn-confirm-contract" : ""}
          url={mode === "cancel" ? newContractsPageUrl : ""} 
          onClick={handleOnClick} 
          disabled={isLoading} 
          className={isLoading ? "cursor-not-allowed" : ""}
        >
          {
            mode === "cancel" && cancelStr
          }
          {
            mode === "confirm" && confirmStr
          }
        </Button>
        {mode === "confirm" && checkBox &&
          <label 
            className="inline-flex mt-5 items-center"
            htmlFor="checkBox"
            >
              <input
                type="checkbox"
                id="checkBox"
                name="checkBox"
                defaultChecked={checkBox.defaultChecked}
                className="w-[38px] h-[38px]"
                onChange={checkBox.onChange}
              />
              <span className="ml-3">
                {checkBox.label}
              </span>
          </label>
        }
      </Modal>
    )
}
