import React from 'react'
import {
    Document,
    Font,
    Image,
    Link,
    Page,
    StyleSheet,
    Text,
    View,
} from '@react-pdf/renderer'
import { IContractConfirmation, IContractConfirmationItem } from '../../../src/types/interfaces/IContractConfirmation'
import { horizomPlatformsLogo, hireHubLogo, linkedInLogo, mailLogo } from '../base64-images'

interface IContractConfirmationProps {
    contract: IContractConfirmation
}

Font.register({
    family: 'Arimo',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/arimo/v29/P5sfzZCDf9_T_3cV7NCUECyoxNk37cxsBxDAVQI4aA.ttf',
      },
      {
        src: 'https://fonts.gstatic.com/s/arimo/v29/P5sfzZCDf9_T_3cV7NCUECyoxNk3CstsBxDAVQI4aA.ttf',
        fontWeight: 'bold',
      },
      {
        src: 'https://fonts.gstatic.com/s/arimo/v29/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-ERBrEdwcoaKww.ttf',
        fontWeight: 'normal',
        fontStyle: 'italic',
      },
    ],
  });

const styles = StyleSheet.create({
    body: {
        width: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
    },
    container: {
        width: '100%',
    },
    divider: {
        borderTop: '1px',
        borderStyle: 'solid',
        borderColor: '#EEEEEE',
        width: '100%',
    },
    spacerSmall: {
        marginTop: '5px',
    },
    spacer: {
        marginTop: '10px',
    },
    header: {
        height: '60px',
        marginTop: '30px',
        marginBottom: '15px',
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
    },
    headerImageContainer: {
        width: '200px',
        height: '75px',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
    },
    imageHorizonPlatformsLogo: {
        width: '80px',
        height: 'auto',
        marginLeft: '60px',
    },
    imageHireHubLogo: {
        width: '150px',
        height: 'auto',
        marginTop: '5px',
        marginLeft: '25px',
    },
    text: {
        fontFamily: 'Arimo',
        fontSize: '9px',
        lineHeight: 1.2,
        color: '#555555',
        marginTop: '6px',
        marginBottom: '6px',
        padding: 0,
    },
    textSmall: {
        fontSize: '8px',
    },
    textBold: {
        fontWeight: 'bold',
    },
    textItalic: {
        fontStyle: 'italic',
    },
    textCollapsed: {
        marginTop: '0',
        marginBottom: '3px',
    },
    textCollapsedFirst: {
        marginBottom: '3px',
    },
    table: {
        width: '100%',
        borderTop: '1px',
        borderLeft: '1px',
        borderStyle: 'solid',
        borderColor: '#EEEEEE',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
    },
    tableCell: {
        borderRight: '1px',
        borderBottom: '1px',
        borderStyle: 'solid',
        borderColor: '#EEEEEE',
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
    },
    tableCellText: {
        fontFamily: 'Arimo',
        fontSize: '8px',
        color: '#555555',
        margin: '6px',
    },
    tableCellDetailsCol1: {
        width: '35%',
    },
    tableCellDetailsCol2: {
        width: '65%',
    },
    tableCellItemsCol1: {
        width: '40%',
    },
    tableCellItemsCol2: {
        width: '16%',
    },
    tableCellItemsCol3: {
        width: '16%',
    },
    tableCellItemsCol4: {
        width: '10%',
    },
    tableCellItemsCol5: {
        width: '18%',
    },
    tableCellItemsSummaryCol: {
        width: '82%',
    },
    tableCellSiteCol1: {
        width: '25%',
    },
    tableCellSiteCol2: {
        width: '75%',
    },
    containerInsurance: {
        marginBottom: '10px',
    },
    footer: {
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'row',
    },
    footerText: {
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
    },
    footerLinks: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
    },
    footerImageContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '6px',
    },
    footerImage: {
        display: 'flex',
        flexDirection: 'column',
        width: '20px',
        height: '20px',
        marginRight: '5px',
    },
})

export const ContractConfirmationPDF = (props: IContractConfirmationProps) => {
    const renderItemsHeaderRow = () => {
        return (
            <View style={styles.tableRow}>
                <View style={[styles.tableCell, styles.textBold, styles.tableCellItemsCol1]}>
                    <Text style={styles.tableCellText}>Product</Text>
                </View>
                <View style={[styles.tableCell, styles.textBold, styles.tableCellItemsCol2]}>
                    <Text style={styles.tableCellText}>Code</Text>
                </View>
                <View style={[styles.tableCell, styles.textBold, styles.tableCellItemsCol3]}>
                    <Text style={styles.tableCellText}>Category</Text>
                </View>
                <View style={[styles.tableCell, styles.textBold, styles.tableCellItemsCol4]}>
                    <Text style={styles.tableCellText}>Qty</Text>
                </View>
                <View style={[styles.tableCell, styles.textBold, styles.tableCellItemsCol5]}>
                    <Text style={styles.tableCellText}>Price (ex VAT)</Text>
                </View>
            </View>
        )
    }

    const renderItems = (items: IContractConfirmationItem[]) => {
        if (items) {
            return items.map((item: Record<string, any>) => {
                return (
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCell, styles.tableCellItemsCol1]}>
                            <Text style={styles.tableCellText}>{item.equipmentDescription}</Text>
                        </View>
                        <View style={[styles.tableCell, styles.tableCellItemsCol2]}>
                            <Text style={styles.tableCellText}>{item.stockNumber}</Text>
                        </View>
                        <View style={[styles.tableCell, styles.tableCellItemsCol3]}>
                            <Text style={styles.tableCellText}>{item.equipmentCategory}</Text>
                        </View>
                        <View style={[styles.tableCell, styles.tableCellItemsCol4]}>
                            <Text style={styles.tableCellText}>{item.quantity}</Text>
                        </View>
                        <View style={[styles.tableCell, styles.tableCellItemsCol5]}>
                            <Text style={styles.tableCellText}>£{parseFloat(item.chargeAmount).toFixed(2)}</Text>
                        </View>
                    </View>
                )
            })
        }
    }

    const title = `${props.contract.contractNo} ${props.contract.documentTitle}`
    const subject = `${props.contract.contractNo} ${props.contract.contractType} summary`

    return (
        <Document
            title={title}
            author="Horizon Platforms"
            subject={subject}
        >
            <Page size="A4">
                <View style={styles.body}>
                    <View style={[styles.container, styles.header]}>
                        <View style={styles.headerImageContainer}>
                            <Image style={styles.imageHorizonPlatformsLogo} src={horizomPlatformsLogo}></Image>
                        </View>
                        <View style={styles.headerImageContainer}>
                            <Image style={styles.imageHireHubLogo} src={hireHubLogo}></Image>
                        </View>
                    </View>
                    <View style={styles.divider}></View>
                    <View style={styles.spacer}></View>
                    <View style={styles.container}>
                        <Text style={styles.text}>Hi {props.contract.customerFirstName},</Text>
                    </View>
                    <View style={styles.container}>
                        <Text style={styles.text}>Please do take the time to check the summary below to ensure that everything is correct. If anything doesn't look right, don't panic! Forward this email to <Link src="mailto:info@horizonplatforms.co.uk">info@horizonplatforms.co.uk</Link> with details of the error and one our team will get straight on it for you.</Text>
                    </View>
                    <View style={styles.container}>
                        <Text style={[styles.text, styles.textBold]}>Here is your {props.contract.contractType} summary:</Text>
                    </View>
                    <View style={styles.container}>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCell, styles.textBold, styles.tableCellDetailsCol1]}>
                                    <Text style={styles.tableCellText}>Contract No:</Text>
                                </View>
                                <View style={[styles.tableCell, styles.tableCellDetailsCol2]}>
                                    <Text style={styles.tableCellText}>{props.contract.contractNo}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCell, styles.textBold, styles.tableCellDetailsCol1]}>
                                    <Text style={styles.tableCellText}>Purchase Order No:</Text>
                                </View>
                                <View style={[styles.tableCell, styles.tableCellDetailsCol2]}>
                                    <Text style={styles.tableCellText}>{props.contract.orderNo}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCell, styles.textBold, styles.tableCellDetailsCol1]}>
                                    <Text style={styles.tableCellText}>Planned Hire Start Date:</Text>
                                </View>
                                <View style={[styles.tableCell, styles.tableCellDetailsCol2]}>
                                    <Text style={styles.tableCellText}>{props.contract.hireStartDate}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCell, styles.textBold, styles.tableCellDetailsCol1]}>
                                    <Text style={styles.tableCellText}>Planned Hire End Date:</Text>
                                </View>
                                <View style={[styles.tableCell, styles.tableCellDetailsCol2]}>
                                    <Text style={styles.tableCellText}>{props.contract.plannedEndDate}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.spacer}></View>
                    <View style={styles.container}>
                        <Text style={[styles.text, styles.textBold]}>Order Items*</Text>
                    </View>
                    <View style={styles.container}>
                        <View style={styles.table}>
                            {renderItemsHeaderRow()}
                            {renderItems(props.contract.contractItems)}
                            {!props.contract.isWeeklyCharge && (
                                <>
                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableCell, styles.tableCellItemsSummaryCol]}>
                                            <Text style={styles.tableCellText}>Delivery</Text>
                                        </View>
                                        <View style={[styles.tableCell, styles.tableCellItemsCol5]}>
                                            <Text style={styles.tableCellText}>£{props.contract.deliveryCharge.toFixed(2)}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableCell, styles.tableCellItemsSummaryCol]}>
                                            <Text style={styles.tableCellText}>Collection</Text>
                                        </View>
                                        <View style={[styles.tableCell, styles.tableCellItemsCol5]}>
                                            <Text style={styles.tableCellText}>£{props.contract.collectionCharge.toFixed(2)}</Text>
                                        </View>
                                    </View>
                                </>
                            )}
                            {!props.contract.hasInsurance && !!props.contract.theftAndLossWaiverPercentage && (
                                <View style={styles.tableRow}>
                                    <View style={[styles.tableCell, styles.tableCellItemsSummaryCol]}>
                                        <Text style={styles.tableCellText}>Theft & Loss Waiver @ {props.contract.theftAndLossWaiverPercentage}% of the total hire cost</Text>
                                    </View>
                                    <View style={[styles.tableCell, styles.tableCellItemsCol5]}>
                                        <Text style={styles.tableCellText}>£{props.contract.theftAndLossWaiverCharge.toFixed(2)}</Text>
                                    </View>
                                </View>
                            )}
                            {!props.contract.hasInsurance && !!props.contract.accidentalDamageWaiverPercentage && (
                                <View style={styles.tableRow}>
                                    <View style={[styles.tableCell, styles.tableCellItemsSummaryCol]}>
                                        <Text style={styles.tableCellText}>Damage Waiver @ {props.contract.accidentalDamageWaiverPercentage}% of the total hire cost</Text>
                                    </View>
                                    <View style={[styles.tableCell, styles.tableCellItemsCol5]}>
                                        <Text style={styles.tableCellText}>£{props.contract.accidentalDamageWaiverCharge.toFixed(2)}</Text>
                                    </View>
                                </View>
                            )}
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCell, styles.tableCellItemsSummaryCol]}>
                                    <Text style={styles.tableCellText}>{`${props.contract.isWeeklyCharge ? "Weekly" : "Total"} Charge`}</Text>
                                </View>
                                <View style={[styles.tableCell, styles.tableCellItemsCol5]}>
                                    <Text style={styles.tableCellText}>£{props.contract.orderValue.toFixed(2)}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.container}>
                            <Text style={[styles.text, styles.textItalic, styles.textSmall]}>
                                * We may supply you with an equivalent to the machine(s) listed above.
                            </Text>
                        </View>

                        {props.contract.isWeeklyCharge && (
                            <>
                                <View style={styles.spacerSmall}></View>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableCell, styles.textBold, styles.tableCellItemsSummaryCol]}>
                                            <Text style={styles.tableCellText}>Transport Charges</Text>
                                        </View>
                                        <View style={[styles.tableCell, styles.textBold, styles.tableCellItemsCol5]}>
                                            <Text style={styles.tableCellText}>Price (ex VAT)</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableCell, styles.tableCellItemsSummaryCol]}>
                                            <Text style={styles.tableCellText}>Delivery</Text>
                                        </View>
                                        <View style={[styles.tableCell, styles.tableCellItemsCol5]}>
                                            <Text style={styles.tableCellText}>£{props.contract.deliveryCharge.toFixed(2)}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableCell, styles.tableCellItemsSummaryCol]}>
                                            <Text style={styles.tableCellText}>Collection</Text>
                                        </View>
                                        <View style={[styles.tableCell, styles.tableCellItemsCol5]}>
                                            <Text style={styles.tableCellText}>£{props.contract.collectionCharge.toFixed(2)}</Text>
                                        </View>
                                    </View>
                                </View>
                            </>
                        )}

                        {props.contract.fuelItems.length > 0 && (
                            <>
                                <View style={styles.spacerSmall}></View>
                                <View style={styles.container}>
                                    <Text style={styles.text}>The following items will also be chargeable on a sale or return basis:</Text>
                                </View>
                                <View style={styles.table}>
                                    {renderItemsHeaderRow()}
                                    {renderItems(props.contract.fuelItems)}
                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableCell, styles.tableCellItemsSummaryCol]}>
                                            <Text style={styles.tableCellText}>Total</Text>
                                        </View>
                                        <View style={[styles.tableCell, styles.tableCellItemsCol5]}>
                                            <Text style={styles.tableCellText}>£{props.contract.totalFuelCharge.toFixed(2)}</Text>
                                        </View>
                                    </View>
                                </View>
                            </>
                        )}
                    </View>
                    <View style={styles.spacerSmall}></View>
                    <View style={styles.container}>
                        <Text style={styles.text}>{props.contract.contractType === "order" ? "Should you wish to cancel this order, then please contact us within a minimum of 24 hours from the hire start date. " : ""}All fixed term hires will be shown with 'Total Charge'. Weekly or ongoing continuous hires are shown with 'Weekly Charge'. All weekly hires will be invoiced on an ongoing basis until and unless you contact us when you wish to terminate the hire.</Text>
                    </View>
                    {props.contract.hasInsurance && (
                        <View style={styles.container}>
                            <Text style={[styles.text, styles.textBold]}>You have identified that you have your own insurance cover for this {props.contract.contractType}.</Text>
                        </View>
                    )}
                    {!props.contract.hasInsurance && (
                        <View style={styles.containerInsurance}>
                            <View style={styles.container}>
                                <Text style={[styles.text, styles.textBold]}>You have included the following Waivers as part of your {props.contract.contractType}:</Text>
                            </View>
                            <View style={styles.container}>
                                <Text style={[styles.text, styles.textSmall, styles.textCollapsed]}>Theft & Loss Waiver @ {props.contract.theftAndLossWaiverPercentage}% of the total hire cost</Text>
                            </View>
                            <View style={styles.container}>
                                <Text style={[styles.text, styles.textSmall, styles.textCollapsed]}>Damage Waiver @ {props.contract.accidentalDamageWaiverPercentage}% of the total hire cost</Text>
                            </View>
                        </View>
                    )}
                    <View style={styles.container}>
                        <Text style={[styles.text, styles.textBold]}>Site</Text>
                    </View>
                    <View style={styles.container}>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCell, styles.tableCellSiteCol1]}>
                                    <Text style={[styles.tableCellText, styles.textBold]}>Site Name:</Text>
                                </View>
                                <View style={[styles.tableCell, styles.tableCellSiteCol2]}>
                                    <Text style={styles.tableCellText}>{props.contract.siteName}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCell, styles.tableCellSiteCol1]}>
                                    <Text style={[styles.tableCellText, styles.textBold]}>Address:</Text>
                                </View>
                                <View style={[styles.tableCell, styles.tableCellSiteCol2]}>
                                    {props.contract.siteAddressLine1 && (
                                        <Text style={[styles.tableCellText, styles.textCollapsedFirst]}>{props.contract.siteAddressLine1}</Text>
                                    )}
                                    {props.contract.siteAddressLine2 && (
                                        <Text style={[styles.tableCellText, styles.textCollapsed]}>{props.contract.siteAddressLine2}</Text>
                                    )}
                                    {props.contract.siteAddressLine3 && (
                                        <Text style={[styles.tableCellText, styles.textCollapsed]}>{props.contract.siteAddressLine3}</Text>
                                    )}
                                    {props.contract.siteTown && (
                                        <Text style={[styles.tableCellText, styles.textCollapsed]}>{props.contract.siteTown}</Text>
                                    )}
                                    {props.contract.siteCounty && (
                                        <Text style={[styles.tableCellText, styles.textCollapsed]}>{props.contract.siteCounty}</Text>
                                    )}
                                    {props.contract.sitePostCode && (
                                        <Text style={[styles.tableCellText, styles.textCollapsed]}>{props.contract.sitePostCode}</Text>
                                    )}
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCell, styles.tableCellSiteCol1]}>
                                    <Text style={[styles.tableCellText, styles.textBold]}>Site Contact:</Text>
                                </View>
                                <View style={[styles.tableCell, styles.tableCellSiteCol2]}>
                                    <Text style={styles.tableCellText}>{props.contract.siteContact}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.spacer}></View>
                    <View style={styles.container}>
                        <Text style={styles.text}>Thanks again for choosing Horizon Platforms! If you would like to view the details of this {props.contract.contractType}, why not sign up to HireHub where you can see what you ordered, what is out on hire and even offhire all from one place. You can create an account in a few simple steps at <Link src="https://www.horizonplatforms.co.uk/my-account">HireHub</Link>.</Text>
                    </View>
                    <View style={styles.spacer}></View>
                    <View style={styles.container}>
                        <Text style={styles.text}>Horizon Platforms Team</Text>
                    </View>
                    <View style={styles.spacer}></View>
                    <View style={styles.divider}></View>
                    <View style={styles.spacer}></View>
                    <View style={[styles.container, styles.footer]}>
                        <View style={styles.footerText}>
                            <Text style={[styles.text, styles.textSmall, styles.textCollapsedFirst]}>Horizon Platforms</Text>
                            <Text style={[styles.text, styles.textSmall, styles.textCollapsed]}>Unit G1, Rosie Road, Normanton,</Text>
                            <Text style={[styles.text, styles.textSmall, styles.textCollapsed]}>West Yorkshire, WF6 1ZB</Text>
                            <Text style={[styles.text, styles.textSmall, styles.textCollapsed]}>Tel: 0330 134 6458</Text>
                            <Text style={[styles.text, styles.textSmall, styles.textCollapsed]}><Link src="https://www.horizonplatforms.co.uk">www.horizonplatforms.co.uk</Link></Text>
                        </View>
                        <View style={styles.footerLinks}>
                            <View style={styles.footerImageContainer}>
                                <View style={styles.footerImage}>
                                    <Link src="https://www.linkedin.com/company/horizon-platforms-ltd">
                                        <Image src={linkedInLogo}></Image>
                                    </Link>
                                </View>
                                <View style={styles.footerImage}>
                                    <Link src="mailto:info@horizonplatforms.co.uk">
                                        <Image src={mailLogo}></Image>
                                    </Link>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}