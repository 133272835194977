import React, { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { IBadData } from "../../types/interfaces"
import { useResourceStringsNewContracts } from "../pages/cx-dashboard/customer/customer-detail/new-contracts/use-resource-strings"
import AsyncSelect from "react-select/async"
import { isPostcodeExcluded } from "../../utils/postcode-exclusions-helper"
import { useAllKontentPostcodeExclusionsData } from "../../graphql-static/use-postcode-exclusions"

export interface IAddAmendAddressModalProps {
  handleDetailChange: (key: string, value: string | Record<string, any>) => void
  isOpen: boolean
  setIsOpen: (bool: boolean) => void
  isOpenConfirm?: boolean
  setIsOpenConfirm?: (bool: boolean) => void
  ctaLabel: Record<string, any>
  setNewAddressValues: any
  newAddressValues: Record<string, any>
  setCanAmend: any
  addressKeyName: string
}

export const AddAmendAddressModal = ({
  handleDetailChange,
  isOpen,
  setIsOpen,
  isOpenConfirm = false,
  setIsOpenConfirm,
  ctaLabel,
  setNewAddressValues,
  newAddressValues,
  setCanAmend,
  addressKeyName,
}: IAddAmendAddressModalProps) => {
  const { amendExistingContactWarning } = useResourceStringsNewContracts()

  const [showCreateAddressBadDataMessage, setCreateAddressShowBadDataMessage] =
    useState<IBadData>({
      showMessage: false,
      message: "",
    })

  const excludedPostcodes = useAllKontentPostcodeExclusionsData()

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function closeModalConfirm() {
    setIsOpenConfirm && setIsOpenConfirm(false)
  }

  const handleNewAddressChange = (
    key: string,
    value: string | Record<string, any>
  ) => {
    setNewAddressValues({
      ...newAddressValues,
      [key]: value,
    })
  }

  const handlePostcodeLookupSet = (value: any) => {
    if (value) {
      setNewAddressValues({
        addressName: "",
        addressLine1: value?.value?.line_1,
        addressLine2: value?.value?.line_2,
        addressTown: value?.value?.town_or_city,
        addressCounty: value?.value?.county,
        addressPostcode: value?.value?.postcode?.replace(/  +/g, " ") || "",
        addressDirections: "",
      })
    }
  }

  const onConfirm = () => {
    if (
      newAddressValues.addressName !== "" &&
      newAddressValues.addressLine1 !== "" &&
      newAddressValues.addressPostcode !== ""
    ) {
      const address = {
        label: `${newAddressValues.addressName}, ${
          newAddressValues.addressLine1
        }, ${
          newAddressValues.addressLine2 && newAddressValues.addressLine2 + ","
        } ${
          newAddressValues.addressTown && newAddressValues.addressTown + ","
        } ${
          newAddressValues.addressCounty && newAddressValues.addressCounty + ","
        } ${newAddressValues.addressPostcode}`,
        value: { ...newAddressValues, update: true },
      }
      handleDetailChange(addressKeyName, address)
      setCanAmend(true)
      setCreateAddressShowBadDataMessage({ showMessage: false, message: "" })
      closeModal()
    } else {
      setCreateAddressShowBadDataMessage({
        showMessage: true,
        message: "The required fields have not been completed",
      })
    }
  }

  const promiseOptions = async (inputValue: string) => {
    if (inputValue.length >= 6) {
      let options = []
      try {
        const postcodeLookupUrl =
          "https://api.getaddress.io/find/" +
          inputValue +
          "?api-key=6CmmDuFJjUOAWoRMELZs2Q24075&expand=true"
        const results = await fetch(postcodeLookupUrl, {
          method: "GET",
        })
          .then(response => response.json())
          .then(result => {
            if (typeof result.addresses !== "undefined") {
              return result.addresses
            } else {
              return []
            }
          })
        const addressResults = results.map(
          (item: Record<string, any>) => (
            (item.postcode = inputValue
              .toUpperCase()
              .replace(/^(.*)(\d)/, "$1 $2")),
            {
              label: `${item.line_1}, ${item.line_2 && item.line_2 + ","} ${
                item.town_or_city && item.town_or_city + ","
              } ${item.county && item.county + ","} ${inputValue
                .toUpperCase()
                .replace(/^(.*)(\d)/, "$1 $2")}`,
              value: item,
            }
          )
        )
        options = addressResults
        return options
      } catch (err) {
        console.log(err)
        return []
      }
    }
  }

  const renderExcludedPostcodeWarning = () => {
      const excludedPostcodeResult = isPostcodeExcluded(excludedPostcodes, newAddressValues.addressPostcode)

      if (excludedPostcodeResult.excluded) {
        return (
          <p className="text-sm font-bold text-left text-red-600">Exclusion Postcode</p>
        )
      }

      return null
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 font-normal"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md px-2 py-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 text-gray-900"
                  >
                    {ctaLabel.title}
                  </Dialog.Title>
                  <form>
                    <div className="flex flex-col gap-4 m-4">
                      <AsyncSelect
                        className="text-left sm:text-sm"
                        placeholder={"Postcode Search"}
                        cacheOptions
                        defaultOptions
                        loadOptions={promiseOptions}
                        onChange={result => handlePostcodeLookupSet(result)}
                      />
                      <div>
                        <p className="text-sm font-bold text-left">
                          Site Name *
                        </p>
                        <input
                          type="text"
                          placeholder={"Site Name *"}
                          className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none h-9 sm:text-sm"
                          maxLength={50}
                          value={newAddressValues.addressName || ""}
                          onChange={(value: any) =>
                            handleNewAddressChange(
                              "addressName",
                              value.target.value
                            )
                          }
                        />
                      </div>
                      <div>
                        <p className="text-sm font-bold text-left">
                          Address Line 1 *
                        </p>
                        <input
                          type="text"
                          placeholder={"Address Line 1 *"}
                          className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none h-9 sm:text-sm"
                          maxLength={50}
                          value={newAddressValues.addressLine1 || ""}
                          onChange={(value: any) =>
                            handleNewAddressChange(
                              "addressLine1",
                              value.target.value
                            )
                          }
                        />
                      </div>
                      <div>
                        <p className="text-sm font-bold text-left">
                          Address Line 2
                        </p>
                        <input
                          type="text"
                          placeholder={"Address Line 2"}
                          className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none h-9 sm:text-sm"
                          maxLength={50}
                          value={newAddressValues.addressLine2 || ""}
                          onChange={(value: any) =>
                            handleNewAddressChange(
                              "addressLine2",
                              value.target.value
                            )
                          }
                        />
                      </div>
                      <div className="flex flex-row gap-2">
                        <div>
                          <p className="text-sm font-bold text-left">
                            Town / City
                          </p>
                          <input
                            type="text"
                            placeholder={"Town / City"}
                            className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none h-9 sm:text-sm"
                            maxLength={50}
                            value={newAddressValues.addressTown || ""}
                            onChange={(value: any) =>
                              handleNewAddressChange(
                                "addressTown",
                                value.target.value
                              )
                            }
                          />
                        </div>
                        <div>
                          <p className="text-sm font-bold text-left">County</p>
                          <input
                            type="text"
                            placeholder={"County"}
                            className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none h-9 sm:text-sm"
                            maxLength={50}
                            value={newAddressValues.addressCounty || ""}
                            onChange={(value: any) =>
                              handleNewAddressChange(
                                "addressCounty",
                                value.target.value
                              )
                            }
                          />
                        </div>
                      </div>

                      <div>
                        <div className="flex flex-row">
                          <div className="flex flex-col flex-grow">
                            <p className="text-sm font-bold text-left">
                              Postcode *
                            </p>
                          </div>
                          <div className="flex flex-col flex-row-reverse">
                            {renderExcludedPostcodeWarning()}
                          </div>
                        </div>
                        <input
                          type="text"
                          placeholder={"Postcode *"}
                          className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none h-9 sm:text-sm"
                          maxLength={8}
                          value={newAddressValues.addressPostcode || ""}
                          onChange={(value: any) => {
                            handleNewAddressChange(
                              "addressPostcode",
                              value.target.value
                            )
                          }}
                        />
                        
                      </div>

                      <div>
                        <p className="text-sm font-bold text-left">
                          Site Directions
                        </p>
                        <textarea
                          placeholder={"Site Directions"}
                          className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none sm:text-sm"
                          maxLength={512}
                          value={newAddressValues.addressDirections || ""}
                          onChange={(value: any) =>
                            handleNewAddressChange(
                              "addressDirections",
                              value.target.value
                            )
                          }
                        />
                      </div>
                      {showCreateAddressBadDataMessage.showMessage && (
                        <p className="text-sm font-bold text-horizonred">
                          {showCreateAddressBadDataMessage.message}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-row gap-2 mx-4">
                      <div className="flex flex-1">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            closeModal()
                          }}
                          className="w-full px-4 py-2 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="flex flex-1">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            onConfirm()
                          }}
                          className="w-full px-4 py-2 text-white rounded-full bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                        >
                          {ctaLabel.cta}
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpenConfirm} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 font-normal"
          onClose={closeModalConfirm}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md px-2 py-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 text-gray-900"
                  >
                    CONFIRM AMENDMENT OF EXISTING ADDRESS
                  </Dialog.Title>
                  <form>
                    <div className="flex flex-col gap-4 m-4">
                      <div className="flex flex-row gap-2">
                        <p className="text-sm font-bold">
                          {amendExistingContactWarning}
                        </p>
                      </div>
                      {showCreateAddressBadDataMessage.showMessage && (
                        <p className="text-sm font-bold text-horizonred">
                          {showCreateAddressBadDataMessage.message}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-row gap-2 mx-4">
                      <div className="flex flex-1">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            closeModalConfirm()
                          }}
                          className="w-full px-4 py-2 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="flex flex-1">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            closeModalConfirm()
                            setTimeout(() => {
                              openModal()
                            }, 300)
                          }}
                          className="w-full px-4 py-2 text-white rounded-full bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                        >
                          CONFIRM
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
