import React, { useState } from "react"
import { useResourceStrings } from "../pages/cx-dashboard/use-resource-strings"
import DatePicker from "react-datepicker"
import Select from "react-select"
import { useCustomerContext } from "../pages/cx-dashboard/customer/store"
import { useMsal } from "@azure/msal-react"
import { offhire, updateContactEmail } from "../../middleware/middleware-layer"
import { IContractDetailItem } from "../../types/interfaces/IContractDetail"
import { classNames } from "../../utils/classNames"
import { Link, navigate } from "gatsby"
import { resourceStringExtractor } from "../../utils/resource-string-extractor"
import { Switch } from "@headlessui/react"
import { AddContactEmailModal } from "../add-contact-email-modal"

export interface IArrangeCollectionFormProps {
  customerId: string
  contractId: string
  contactsList: any[]
}

export const OffHireForm = (props: IArrangeCollectionFormProps) => {
  const {
    arrangeCollectionSubheading,
    arrangeCollectionRequested,
    arrangeCollectionRequestedPlaceholder,
    arrangeCollectionOffhireDate,
    arrangeCollectionOffhireDatePlaceholder,
    arrangeCollectionCollectionDate,
    arrangeCollectionCollectionDatePlaceholder,
    arrangeCollectionCollectionSlot,
    arrangeCollectionCollectionNotes,
    arrangeCollectionCollectionNotesPlaceholder,
    arrangeCollectionSubmit,
    arrangeCollectionFormIntro,
    arrangeCollectionAutoOffhireTitle,
    arrangeCollectionAutoOffireErrorMessage,
  } = useResourceStrings()

  const { offHireItems } = useCustomerContext()

  const { accounts, instance } = useMsal()

  const deliveryTimeString = resourceStringExtractor(
    "AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS"
  )

  const deliveryTimeOptions = [
    { label: deliveryTimeString("DELIVERYTIME1"), value: "17:00" },
    { label: deliveryTimeString("DELIVERYTIME2"), value: "08:00" },
    { label: deliveryTimeString("DELIVERYTIME3"), value: "08:30" },
    { label: deliveryTimeString("DELIVERYTIME4"), value: "09:00" },
    { label: deliveryTimeString("DELIVERYTIME5"), value: "09:30" },
    { label: deliveryTimeString("DELIVERYTIME6"), value: "10:00" },
    { label: deliveryTimeString("DELIVERYTIME7"), value: "10:30" },
    { label: deliveryTimeString("DELIVERYTIME8"), value: "11:00" },
    { label: deliveryTimeString("DELIVERYTIME9"), value: "11:30" },
    { label: deliveryTimeString("DELIVERYTIME10"), value: "12:00" },
    { label: deliveryTimeString("DELIVERYTIME11"), value: "12:30" },
    { label: deliveryTimeString("DELIVERYTIME12"), value: "13:00" },
    { label: deliveryTimeString("DELIVERYTIME13"), value: "14:00" },
    { label: deliveryTimeString("DELIVERYTIME14"), value: "15:00" },
    { label: deliveryTimeString("DELIVERYTIME15"), value: "16:00" },
    { label: deliveryTimeString("DELIVERYTIME16"), value: "17:00" },
  ]

  const initialFormState: Record<string, any> = {
    contact: { label: "", value: null },
    arrangeCollectionOffhireDate: null,
    arrangeCollectionTransportCharge: null,
    arrangeCollectionCollectionDate: null,
    arrangeCollectionCollectionSlot: null,
    isAutoOffhire: false,
    notes: "",
    offhireNotes: "",
  }
  const [formValues, setFormValues] = useState(initialFormState)
  const [badSubmit, setBadSubmit] = useState(false)
  const [pickerCollectionDate, setPickerCollectionDate] = useState<Date>()
  const [pickerOffhireDate, setPickerOffhireDate] = useState<Date>()
  const [displayAutoOffHireError, setDisplayAutoOffHireError] =
    useState<boolean>(false)
  const [submitting, setSubmitting] = useState(false)
  const [isOpenContactEmailModal, setIsOpenContactEmailModal] = useState<boolean>(false)
  const [updateContactEmailFailed, setUpdateContactEmailFailed ] = useState(false)

  const toggleAutoOffhire = () => {
    if (formValues.arrangeCollectionOffhireDate === null) {
      setFormValues({
        ...formValues,
        isAutoOffhire: false,
      })
      setDisplayAutoOffHireError(true)
      return
    }
    if (displayAutoOffHireError === true) {
      setDisplayAutoOffHireError(false)
    }
    handleOnChange("isAutoOffhire", !formValues?.isAutoOffhire)
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    // If the contact doesn't have an email address we will never send the off hire confirmation email, so
    // check and ask for it if necessary.
    if (!formValues?.contact?.value?.contactEmail) {
      setIsOpenContactEmailModal(true)
      return
    }

    submitForm()
  }

  const submitForm = async () => {  
    setSubmitting(true)
    try {
      const preparedOffhireItems = offHireItems.map(
        (item: IContractDetailItem) => ({
          stockNumber: item.stockNumber,
          sequenceNo: item.sequenceNo,
        })
      )

      var offhireDate = new Date(formValues?.arrangeCollectionOffhireDate)
      offhireDate.setHours(offhireDate.getHours() + 1)

      var offhireCollectionDate
      if(formValues?.arrangeCollectionCollectionDate !== null) {
        offhireCollectionDate = new Date(formValues?.arrangeCollectionCollectionDate)
        offhireCollectionDate.setHours(offhireCollectionDate.getHours() + 1)
      } else {
        offhireCollectionDate = offhireDate
      }

      await offhire(
        accounts,
        instance,
        +props.customerId,
        +props.contractId,
        offhireDate.toISOString().substring(0,11) + "00:00:00.000Z",
        offhireCollectionDate.toISOString().substring(0,11) + "00:00:00.000Z",
        formValues?.arrangeCollectionCollectionSlot?.value,
        preparedOffhireItems,
        formValues?.contact?.value,
        formValues?.notes,
        formValues?.isAutoOffhire,
        formValues?.offhireNotes,
      )
      navigate(
        `/cx-dashboard/customer/${props.customerId}/contracts/${props.contractId}`
      )
    } catch (error) {
      console.log(error)
      setSubmitting(false)
      setBadSubmit(true)
    }
  }

  const handleOnChange = (key: string, value: any) => {
    setFormValues({
      ...formValues,
      [key]: value,
    })
  }

  const handleEmailAddressEntered = async (contactEmail: string) => {
    // Update the contact's email address, and if successful set the form value to suit and then re-submit the off hire form.
    setIsOpenContactEmailModal(false)

    const result = await updateContactEmail(
      accounts, 
      instance, 
      formValues.contact.value.contactId,
      contactEmail
    )

    if (result.data.internalUpdateContact.contactId === formValues.contact.value.contactId) {
      formValues.contact.value.contactEmail = contactEmail
      submitForm()
    } else {
      setUpdateContactEmailFailed(true)
    }
  }

  const submitDisabled =
    !offHireItems?.length ||
    !formValues?.contact?.value ||
    !formValues?.arrangeCollectionOffhireDate ||
    !formValues?.notes ||
    submitting

  return (
    <>
      <form className="ml-5" onSubmit={handleSubmit}>
        <div>
          <h2 className="text-2xl font-bold uppercase">
            {arrangeCollectionSubheading}
          </h2>
          <p className="text-lg">{arrangeCollectionFormIntro}</p>
          <p className="mt-5 text-lg">An '*' indicates a required field.</p>
        </div>

        <div className="">
          <h3 className="my-5 text-lg font-bold">{arrangeCollectionRequested}</h3>
          <Select
            id={"contact"}
            value={formValues["contact"]}
            placeholder={arrangeCollectionRequestedPlaceholder}
            isClearable
            className="w-1/2 border-gray-300 rounded-md shadow-sm sm:text-sm"
            onChange={contact => handleOnChange("contact", contact)}
            options={props.contactsList}
          />

          <h3 className="my-5 text-lg font-bold">
            {arrangeCollectionOffhireDate}
          </h3>
          <div className="relative">
            <DatePicker
              selected={pickerOffhireDate ? pickerOffhireDate : null}
              onChange={(date: Date) => {
                setPickerOffhireDate(date)
                handleOnChange("arrangeCollectionOffhireDate", date)
              }}
              dateFormat="dd-MM-yyyy"
              showDisabledMonthNavigation
              placeholderText={arrangeCollectionOffhireDatePlaceholder}
              className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default sm:text-sm"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={22.065}
              height={24.406}
              className="absolute top-[7px] right-[15px]"
            >
              <defs>
                <style>
                  {
                    ".a{fill:none;stroke:#4c4a4a;stroke-linecap:round;stroke-linejoin:round}"
                  }
                </style>
              </defs>
              <path
                className="a"
                d="M2.841 2.953h16.383a2.334 2.334 0 0 1 2.341 2.328v16.3a2.334 2.334 0 0 1-2.341 2.328H2.841A2.334 2.334 0 0 1 .5 21.578V5.281a2.334 2.334 0 0 1 2.341-2.328ZM15.714.5v4.906M6.351.5v4.906M.5 9.862h21.065"
              />
            </svg>
          </div>

          <div className="flex flex-col w-full">
            <div className="flex items-center w-full mt-5">
              <Switch
                checked={formValues.isAutoOffhire}
                onChange={toggleAutoOffhire}
                className={`${
                  formValues.isAutoOffhire ? "bg-blue-600" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={`${
                    formValues.isAutoOffhire ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <p className="ml-3 text-lg font-bold">
                {arrangeCollectionAutoOffhireTitle}
              </p>
            </div>
            <small
              className={`${
                displayAutoOffHireError ? `d-block` : `hidden`
              } text-horizonred mt-3`}
            >
              {arrangeCollectionAutoOffireErrorMessage}
            </small>
          </div>

          <h3 className="my-5 text-lg font-bold">
            {arrangeCollectionCollectionDate}
          </h3>
          <div className="relative">
            <DatePicker
              selected={pickerCollectionDate ? pickerCollectionDate : null}
              onChange={(date: any) => {
                handleOnChange("arrangeCollectionCollectionDate", date)
                setPickerCollectionDate(date)
              }}
              dateFormat="dd-MM-yyyy"
              showDisabledMonthNavigation
              placeholderText={arrangeCollectionCollectionDatePlaceholder}
              className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default sm:text-sm"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={22.065}
              height={24.406}
              className="absolute top-[7px] right-[15px]"
            >
              <defs>
                <style>
                  {
                    ".a{fill:none;stroke:#4c4a4a;stroke-linecap:round;stroke-linejoin:round}"
                  }
                </style>
              </defs>
              <path
                className="a"
                d="M2.841 2.953h16.383a2.334 2.334 0 0 1 2.341 2.328v16.3a2.334 2.334 0 0 1-2.341 2.328H2.841A2.334 2.334 0 0 1 .5 21.578V5.281a2.334 2.334 0 0 1 2.341-2.328ZM15.714.5v4.906M6.351.5v4.906M.5 9.862h21.065"
              />
            </svg>
          </div>

          <h3 className="my-5 text-lg font-bold">
            {arrangeCollectionCollectionSlot}
          </h3>
          <Select
            id="deliveryTime"
            value={formValues.arrangeCollectionCollectionSlot}
            escapeClearsValue={true}
            backspaceRemovesValue={true}
            options={deliveryTimeOptions}
            onChange={(time: any) => {
              handleOnChange("arrangeCollectionCollectionSlot", time)
            }}
            placeholder={arrangeCollectionCollectionSlot}
            className="text-sm"
          />

          <h3 className="my-5 text-lg font-bold">
            {arrangeCollectionCollectionNotes}
          </h3>
          <textarea
            maxLength={512}
            placeholder={arrangeCollectionCollectionNotesPlaceholder}
            className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default h-28 sm:text-sm"
            onChange={(value: any) => handleOnChange("notes", value.target.value)}
          />

          <h3 className="my-5 text-lg font-bold">
            Off-hire notes
          </h3>
          <textarea
            maxLength={400}
            placeholder="Enter any off-hire notes here (will be added after who requested the off-hire)"
            className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default h-28 sm:text-sm"
            onChange={(value: any) => handleOnChange("offhireNotes", value.target.value)}
          />

          <div className="inline-flex justify-between w-full mt-4 mb-8 sm:border-l sm:border-transparent">
            <div className="flex flex-col justify-center">
              <Link
                to={`/cx-dashboard/customer/${props.customerId}/contracts/${props.contractId}`}
                className="text-xs text-right underline"
              >
                Return to order details
              </Link>
            </div>
            <div className="flex flex-col justify-center">
              <button
                id="btn-arrange-collection-submit"
                type="submit"
                className={classNames(
                  submitDisabled
                    ? "cursor-not-allowed"
                    : " hover:bg-horizonhover",
                  "px-6 py-2 text-sm text-white uppercase border-transparent rounded-full bg-horizonred hover:text-grey-300"
                )}
                disabled={submitDisabled}
              >
                {arrangeCollectionSubmit}
              </button>
            </div>
          </div>
          {badSubmit && (
            <p className="w-full py-1 text-2xl font-bold text-horizonred">
              Issue submitting collection form
            </p>
          )}
          {updateContactEmailFailed && (
            <p className="w-full py-1 text-2xl font-bold text-horizonred">
              Failed to add the contact email
            </p>
          )}
        </div>
      </form>

      <AddContactEmailModal
        isOpen={isOpenContactEmailModal}
        setIsOpen={setIsOpenContactEmailModal}
        handleEmailAddressEntered={handleEmailAddressEntered}
      />
    </>
  )
}
