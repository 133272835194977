import React, { useEffect, useState } from "react"
import Select from "react-select"
import CurrencyInput from "react-currency-input-field"
import DateBoxField from "../date-box-field"
import { useResourceStringsNewContracts } from "../pages/cx-dashboard/customer/customer-detail/new-contracts/use-resource-strings"
import { AddAmendContactModal } from "../add-amend-contact-modal"
import { AddAmendAddressModal } from "../add-amend-address-modal"
import SlideDown from "react-slidedown"
import moment from "moment"

export interface IDeliveryDetailsProps {
  deliverySites: any
  deliveryContacts: any
  deliveryTimeOptions: Record<string, any>[]
  deliveryDetailsFormValues: Record<string, any>
  handleDeliveryDetailChange: (
    key: string,
    value: string | boolean | number | Record<string, any> | null
  ) => void
  excludedPostcode: Record<string, any>
  previousExcludedPostcodeTransportCharges: Record<string, any>
}

export const DeliveryDetailsForm = ({
  deliverySites,
  deliveryContacts,
  deliveryTimeOptions,
  deliveryDetailsFormValues,
  handleDeliveryDetailChange,
  excludedPostcode,
  previousExcludedPostcodeTransportCharges,
}: IDeliveryDetailsProps) => {
  const {
    siteAddress,
    siteContact,
    siteDirections,
    depot,
    deliveryDate,
    deliveryTime,
    onHireDate,
    offHireDate,
    deliveryCharge,
    collectionCharge,
    satCharge,
    sunCharge,
    callFirst,
  } = useResourceStringsNewContracts()

  const [isOpenAddress, setIsOpenAddress] = useState<boolean>(false)
  const [isOpenContact, setIsOpenContact] = useState<boolean>(false)
  const [isOpenConfirmContact, setIsOpenConfirmContact] =
    useState<boolean>(false)
  const [isOpenConfirmAddress, setIsOpenConfirmAddress] =
    useState<boolean>(false)
  const [canAmendContact, setCanAmendContact] = useState<boolean>(false)
  const [canAmendAddress, setCanAmendAddress] = useState<boolean>(false)
  const [confirmContactCTA, setConfirmContactCTA] = useState<
    Record<string, any>
  >({
    title: "",
    CTA: "",
  })
  const [confirmAddressCTA, setConfirmAddressCTA] = useState<
    Record<string, any>
  >({
    title: "",
    CTA: "",
  })

  const [previousExcludedPostcodeTransportChargesVisible, setPreviousExcludedPostcodeTransportChargesVisible] = useState<boolean>(false)

  const initialValue = {
    label: "",
    value: null,
  }

  const initialNewContactState: Record<string, any> = {
    contactFirstName: "",
    contactSurname: "",
    contactTelephone: "",
    contactEmail: "",
  }
  const initialNewAddressState: Record<string, any> = {
    addressName: "",
    addressLine1: "",
    addressLine2: "",
    addressTown: "",
    addressCounty: "",
    addressPostcode: "",
    addressDirections: "",
  }

  const [deliveryNewContactValues, setDeliveryNewContactValues] = useState<
    Record<string, any>
  >(initialNewContactState)

  const [deliveryNewAddressValues, setDeliveryNewAddressValues] = useState<
    Record<string, any>
  >(initialNewAddressState)

  function openModalContact() {
    setIsOpenContact(true)
  }

  function openModalAddress() {
    setIsOpenAddress(true)
  }

  const onCreateContact = () => {
    setDeliveryNewContactValues(initialNewContactState)
    setCanAmendContact(false)
    openModalContact()
  }

  const onCreateAddress = () => {
    setDeliveryNewAddressValues(initialNewAddressState)
    setCanAmendAddress(false)
    openModalAddress()
  }

  const decideModalLabels = () => {
    if (deliveryNewContactValues.contactId) {
      setConfirmContactCTA({
        title: "UPDATE EXISTING CONTACT",
        cta: "UPDATE CONTACT",
      })
    } else {
      setConfirmContactCTA({ title: "CREATE NEW CONTACT", cta: "ADD CONTACT" })
    }

    if (deliveryNewAddressValues.addressId) {
      setConfirmAddressCTA({
        title: "UPDATE EXISTING ADDRESS",
        cta: "UPDATE ADDRESS",
      })
    } else {
      setConfirmAddressCTA({ title: "CREATE NEW ADDRESS", cta: "ADD ADDRESS" })
    }
  }

  const renderPreviousExcludedPostcodeTransportChargeSetter = (value: string) => {
    const valueToSet = parseFloat(value).toFixed(2)
    return (
      <a 
        className="text-blue-600 cursor-pointer" 
        onClick={(e) => {
          e.preventDefault()
          handleDeliveryDetailChange("transportCharge", valueToSet)
        }}
      >
        {`£${valueToSet}`}
      </a>
    )
  }

  const renderPreviousExcludedPostcodeTransportCharges = () => {
    if (excludedPostcode.excluded && 
      previousExcludedPostcodeTransportCharges.hasOwnProperty(excludedPostcode.outerPostcode)) {
        const charges = previousExcludedPostcodeTransportCharges[excludedPostcode.outerPostcode]
        if (charges.length > 0) {
          return (
            <>
              <div className="flex w-full">
                <label className="text-sm">
                  <span>{`Most recent transport charge for this customer and exclusion postcode (${excludedPostcode.outerPostcode}) was `}</span>
                  {renderPreviousExcludedPostcodeTransportChargeSetter(charges[0].transportCharge)}
                  <span> (</span>
                  {charges.length === 1
                      ? <span>no other previous charges</span>
                      : <a 
                          className="underline cursor-pointer"
                          onClick={() => {
                            setPreviousExcludedPostcodeTransportChargesVisible(!previousExcludedPostcodeTransportChargesVisible)
                          }}
                        >
                          {`${previousExcludedPostcodeTransportChargesVisible ? "hide" : "show"}${charges.length === 10 ? " ten": ""} most recent transport charges`}
                        </a>
                    }
                    <span>)</span>
                </label>
              </div>
              <SlideDown>
                {previousExcludedPostcodeTransportChargesVisible && charges.length > 1 && (
                  <>
                    <div className="flex flex-row w-full text-sm font-bold mt-4 bg-gray-300">
                      <div className="flex flex-col w-[10%] p-0.5">Contract</div>
                      <div className="flex flex-col w-[35%] p-0.5">Site</div>
                      <div className="flex flex-col w-[10%] p-0.5">Postcode</div>
                      <div className="flex flex-col w-[10%] text-center p-0.5">On Hire Date</div>
                      <div className="flex flex-col w-[15%] text-center p-0.5">Equipment Code</div>
                      <div className="flex flex-col w-[10%] text-center p-0.5">Transport</div>
                      <div className="flex flex-col w-[10%] text-center p-0.5">XH</div>
                    </div>
                    {charges.map((charge: any) => (
                      <div 
                        key={`previous-charges-${charge.contractId}`}
                        className="flex flex-row w-full text-sm odd:bg-gray-100 even:bg-white">
                          <div className="flex flex-col w-[10%] p-0.5">{charge.contractNo}</div>
                          <div className="flex flex-col w-[35%] p-0.5">{charge.siteName}</div>
                          <div className="flex flex-col w-[10%] p-0.5">{charge.fullPostcode}</div>
                          <div className="flex flex-col w-[10%] text-center p-0.5">
                            {moment(charge.startDate).format("DD/MM/YYYY")}
                          </div>
                          <div className="flex flex-col w-[15%] text-center p-0.5">{charge.equipmentCode}</div>
                          <div className="flex flex-col w-[10%] text-center p-0.5">
                            {renderPreviousExcludedPostcodeTransportChargeSetter(charge.transportCharge)}  
                          </div>
                          <div className="flex flex-col w-[10%] text-center p-0.5">
                            {charge.crossHire ? <>&#10003;</> : <></>}  
                          </div>
                      </div>
                    ))}
                  </>
                )}
              </SlideDown>
            </>
          )
        } else {
          return (
            <div className="flex w-full">
              <label className="text-sm">No previous transport charge for this customer and exclusion postcode</label>
            </div>
          )
        }
    }

    return null
  }

  useEffect(() => {
    decideModalLabels()
    if (deliveryDetailsFormValues?.siteContact?.value != null) {
      setDeliveryNewContactValues(deliveryDetailsFormValues.siteContact.value)
      setCanAmendContact(true)
    } else {
      setCanAmendContact(false)
    }
  }, [deliveryDetailsFormValues?.siteContact])

  useEffect(() => {
    if (deliveryDetailsFormValues?.siteAddress?.value != null) {
      setDeliveryNewAddressValues(deliveryDetailsFormValues.siteAddress.value)
      setCanAmendAddress(true)
      
    } else {
      setCanAmendAddress(false)
    }
  }, [deliveryDetailsFormValues?.siteAddress])

  return (
    <>
      <div className="mb-5 bg-white border rounded-md">
        <div className="px-4 py-4">
          <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2">
            <div className="flex flex-col space-y-2">
              <div className={"flex flex-row gap-2"}>
                <label
                  className="text-sm font-bold truncate"
                  htmlFor="siteAddress"
                >
                  {siteAddress}
                </label>
                <button
                  onClick={e => {
                    e.preventDefault()
                    handleDeliveryDetailChange("siteAddress", initialValue)
                    onCreateAddress()
                  }}
                  className="pb-1 text-right underline bg-white sm:text-xs"
                >
                  New
                </button>
                {JSON.stringify(deliveryNewAddressValues) !==
                  JSON.stringify(initialNewAddressState) &&
                  canAmendAddress && (
                    <button
                      onClick={e => {
                        e.preventDefault()
                        setDeliveryNewAddressValues(
                          deliveryDetailsFormValues?.siteAddress?.value
                        )
                        decideModalLabels()
                        if (
                          deliveryDetailsFormValues?.siteAddress?.value
                            ?.addressId
                        ) {
                          setIsOpenConfirmAddress(true)
                        } else {
                          openModalAddress()
                        }
                      }}
                      className="pb-1 text-right underline bg-white sm:text-xs"
                    >
                      Amend
                    </button>
                  )}
                  {excludedPostcode.excluded && (
                    <div className="flex flex-row flex-grow items-center flex-row-reverse">
                      <label className="text-sm font-bold truncate text-red-600">Exclusion Postcode</label>
                    </div>
                  )}
              </div>
              <Select
                id="siteAddress"
                value={deliveryDetailsFormValues.siteAddress}
                options={deliverySites}
                placeholder={siteAddress}
                className="text-sm"
                onChange={value => {
                  handleDeliveryDetailChange("siteAddress", value)
                  setPreviousExcludedPostcodeTransportChargesVisible(false)
                }}
                isClearable
              />
            </div>
            <div className="flex flex-col space-y-2">
              <div className={"flex flex-row gap-2"}>
                <label
                  className="text-sm font-bold truncate"
                  htmlFor="siteContact"
                >
                  {siteContact}
                </label>
                <button
                  onClick={e => {
                    e.preventDefault()
                    handleDeliveryDetailChange("siteContact", initialValue)
                    onCreateContact()
                  }}
                  className="pb-1 text-right underline bg-white sm:text-xs"
                >
                  New
                </button>
                {JSON.stringify(deliveryNewContactValues) !==
                  JSON.stringify(initialNewContactState) &&
                  canAmendContact && (
                    <button
                      onClick={e => {
                        e.preventDefault()
                        setDeliveryNewContactValues(
                          deliveryDetailsFormValues?.siteContact?.value
                        )
                        decideModalLabels()

                        if (
                          deliveryDetailsFormValues?.siteContact?.value
                            ?.contactId
                        ) {
                          setIsOpenConfirmContact(true)
                        } else {
                          openModalContact()
                        }
                      }}
                      className="pb-1 text-right underline bg-white sm:text-xs"
                    >
                      Amend
                    </button>
                  )}
              </div>

              <Select
                id="siteContact"
                value={deliveryDetailsFormValues.siteContact}
                escapeClearsValue={true}
                backspaceRemovesValue={true}
                onChange={value =>
                  handleDeliveryDetailChange("siteContact", value)
                }
                options={deliveryContacts}
                placeholder={siteContact}
                isClearable
                className="text-sm"
              />
            </div>
          </div>
          <div className="grid w-full grid-cols-1 gap-4 mt-3 sm:grid-cols-2 md:grid-cols-2">
            <div className="flex flex-col space-y-2">
              <label
                className="text-sm font-bold truncate"
                htmlFor="siteDirections"
              >
                {siteDirections}
              </label>
              <textarea
                placeholder={siteDirections}
                value={
                  deliveryDetailsFormValues?.siteAddress?.value
                    ?.addressDirections || ""
                }
                readOnly
                className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label className="text-sm font-bold truncate" htmlFor="depot">
                {depot}
              </label>
              <input
                type="text"
                placeholder={depot}
                value={deliveryDetailsFormValues.depot}
                onChange={(e: any) =>
                  handleDeliveryDetailChange("depot", e.target.value)
                }
                disabled
                className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="grid w-full grid-cols-1 gap-4 mt-3 mb-2 sm:grid-cols-2 md:grid-cols-4">
            <div className="flex flex-col space-y-2">
              <label
                className="text-sm font-bold truncate"
                htmlFor="deliveryDate"
              >
                {deliveryDate}
              </label>
              <DateBoxField
                date={deliveryDetailsFormValues.deliveryDate}
                onChange={(date: Date) =>
                  handleDeliveryDetailChange("deliveryDate", date)
                }
                label=""
                className="flex items-center w-full"
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label
                className="text-sm font-bold truncate"
                htmlFor="deliveryTime"
              >
                {deliveryTime}
              </label>
              <Select
                id="deliveryTime"
                value={deliveryDetailsFormValues.deliveryTime}
                escapeClearsValue={true}
                backspaceRemovesValue={true}
                options={deliveryTimeOptions}
                onChange={(time: any) => {
                  handleDeliveryDetailChange("deliveryTime", time)
                }}
                placeholder="Delivery Time"
                className="text-sm"
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label
                className="text-sm font-bold truncate"
                htmlFor="onHireDate"
              >
                {onHireDate}
              </label>
              <DateBoxField
                date={deliveryDetailsFormValues.onHireDate}
                onChange={(date: Date) =>
                  handleDeliveryDetailChange("onHireDate", date)
                }
                label=""
                className="flex items-center w-full"
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label
                className="text-sm font-bold truncate"
                htmlFor="offHireDate"
              >
                {offHireDate}
              </label>
              <DateBoxField
                date={deliveryDetailsFormValues.offHireDate}
                onChange={(date: Date) => {
                  handleDeliveryDetailChange("offHireDate", date)
                }}
                label=""
                className="flex items-center w-full"
              />
            </div>
          </div>

          <div className="grid w-full grid-cols-1 gap-4 mt-3 mb-2 sm:grid-cols-2 md:grid-cols-2">
            <div className="grid w-full grid-cols-1 gap-4 mt-3 mb-2 sm:grid-cols-2 md:grid-cols-2">
              <div className="flex flex-col space-y-2">
                <label
                  className="text-sm font-bold truncate"
                  htmlFor="deliveryCharge"
                >
                  {deliveryCharge}
                </label>
                <CurrencyInput
                  id="deliveryCharge"
                  name="deliveryCharge"
                  placeholder={deliveryCharge}
                  prefix="£"
                  value={deliveryDetailsFormValues.deliveryCharge}
                  decimalsLimit={2}
                  decimalScale={2}
                  onValueChange={(value = "") => {
                    handleDeliveryDetailChange("deliveryCharge", value)
                  }}
                  allowNegativeValue={false}
                  className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label
                  className="text-sm font-bold truncate"
                  htmlFor="collectionCharge"
                >
                  {collectionCharge}
                </label>
                <CurrencyInput
                  id="collectionCharge"
                  name="collectionCharge"
                  placeholder={collectionCharge}
                  prefix="£"
                  value={deliveryDetailsFormValues.collectionCharge}
                  decimalsLimit={2}
                  decimalScale={2}
                  onValueChange={(value = "") => {
                    handleDeliveryDetailChange("collectionCharge", value)
                  }}
                  allowNegativeValue={false}
                  className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="grid w-full grid-cols-3 mt-3 mb-2 md:gap-4 sm:grid-cols-2 lg:grid-cols-3">
              <div className="flex flex-col space-y-2">
                <label
                  className="text-sm font-bold text-center truncate"
                  htmlFor="satCharge"
                >
                  {satCharge}
                </label>
                <input
                  type="checkbox"
                  id="satCharge"
                  name="satCharge"
                  checked={deliveryDetailsFormValues.satChargeBool}
                  onChange={() => {
                    handleDeliveryDetailChange(
                      "satChargeBool",
                      !deliveryDetailsFormValues.satChargeBool
                    )
                  }}
                  className=" w-[38px] h-[38px] self-center"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label
                  className="text-sm font-bold text-center truncate"
                  htmlFor="sunCharge"
                >
                  {sunCharge}
                </label>
                <input
                  type="checkbox"
                  id="sunCharge"
                  name="sunCharge"
                  checked={deliveryDetailsFormValues.sunChargeBool}
                  onChange={() => {
                    handleDeliveryDetailChange(
                      "sunChargeBool",
                      !deliveryDetailsFormValues.sunChargeBool
                    )
                  }}
                  className=" w-[38px] h-[38px] self-center"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label
                  className="text-sm font-bold text-center truncate"
                  htmlFor="callFirst"
                >
                  {callFirst}
                </label>
                <input
                  type="checkbox"
                  id="callFirst"
                  name="callFirst"
                  checked={deliveryDetailsFormValues.callFirst}
                  onChange={() => {
                    handleDeliveryDetailChange(
                      "callFirst",
                      !deliveryDetailsFormValues.callFirst
                    )
                  }}
                  className=" w-[38px] h-[38px] self-center"
                />
              </div>
            </div>
          </div>
          {renderPreviousExcludedPostcodeTransportCharges()}
        </div>
      </div>

      <AddAmendContactModal
        handleDetailChange={handleDeliveryDetailChange}
        isOpen={isOpenContact}
        setIsOpen={setIsOpenContact}
        ctaLabel={confirmContactCTA}
        isOpenConfirm={isOpenConfirmContact}
        setIsOpenConfirm={setIsOpenConfirmContact}
        setNewContactValues={setDeliveryNewContactValues}
        newContactValues={deliveryNewContactValues}
        setCanAmend={setCanAmendContact}
        contactKeyName={"siteContact"}
      />
      <AddAmendAddressModal
        handleDetailChange={handleDeliveryDetailChange}
        isOpen={isOpenAddress}
        setIsOpen={setIsOpenAddress}
        isOpenConfirm={isOpenConfirmAddress}
        setIsOpenConfirm={setIsOpenConfirmAddress}
        ctaLabel={confirmAddressCTA}
        setNewAddressValues={setDeliveryNewAddressValues}
        newAddressValues={deliveryNewAddressValues}
        setCanAmend={setCanAmendAddress}
        addressKeyName={"siteAddress"}
      />
    </>
  )
}
