import React from "react"
import { Router } from "@reach/router"
import { CustomerDetail } from "../../../components/pages/cx-dashboard/customer/customer-detail"
import { Header } from "../../../components/header"
import { DashboardContainer } from "../../../components/dashboard-container"
import NotFound from "../../404"
import ArrangeCollection from "../../../components/pages/cx-dashboard/customer/customer-detail/contracts/contract/arrange-collection"
import { CustomerProvider } from "../../../components/pages/cx-dashboard/customer/store"
import { Contract } from "../../../components/pages/contract"
import OutOnHire from "../../../components/pages/cx-dashboard/customer/customer-detail/contracts/out-on-hire"
import NewContracts from "../../../components/pages/cx-dashboard/customer/customer-detail/new-contracts"
import NewContractDetails from "../../../components/pages/cx-dashboard/customer/customer-detail/new-contracts/contract-details"
import CancelOffhire from "../../../components/pages/cx-dashboard/customer/customer-detail/contracts/contract/cancel-offhire"
import AllContracts from "../../../components/pages/cx-dashboard/customer/customer-detail/contracts/all-contracts"
import HireStatistics from "../../../components/pages/cx-dashboard/customer/customer-detail/hire-statistics"
import HireRates from "../../../components/pages/cx-dashboard/customer/customer-detail/hire-rates"

const CustomerDetailRoot = () => {
  return (
    <CustomerProvider>
      <Header currentPage="cx-dashboard-customer-detail-page" />
      <div className="mt-10">
        <DashboardContainer>
          <Router basepath="/cx-dashboard/customer">
            <NotFound default />
            <CustomerDetail path="/:customerId">
              <OutOnHire path="contracts/out-on-hire" />
              <Contract noContainer={true} path="contracts/:contractId" />
              <ArrangeCollection path="contracts/:contractId/arrange-collection" />
              <CancelOffhire path="contracts/:contractId/cancel-offhire" />
              <NewContracts path="contracts/saved" />
              <NewContractDetails path="contracts/saved/:contractId" />
              <AllContracts path="contracts/all-contracts"/>
              <HireStatistics path="hire-statistics"/>
              <HireRates path="hire-rates"/>
            </CustomerDetail>
          </Router>
        </DashboardContainer>
      </div>
    </CustomerProvider>
  )
}
export default CustomerDetailRoot
