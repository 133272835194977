import React from "react"
import { LoadingFeedback } from "../loading-feedback"
import { 
    Chart as ChartJS, 
    ArcElement, 
    Tooltip, 
    Legend, 
    CategoryScale, 
    LinearScale, 
    BarElement, 
  } from 'chart.js';
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  import { 
    Bar, 
    Doughnut 
  } from 'react-chartjs-2';

interface HireStatisticsChartsProps {
    charts: Record<string, any>[]
    emptyChartSubTitle?: string
    isLoading: boolean
    statisticsUnavailable: boolean
    statisticsUnavailableMessage?: string
  }

export const HireStatisticsCharts = ({
    charts,
    emptyChartSubTitle = 'There is no data to display',
    isLoading,
    statisticsUnavailable = false,
    statisticsUnavailableMessage = 'There are no statistics available.',
  }: HireStatisticsChartsProps) => {
    ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale,BarElement, ChartDataLabels)

    const segmentsBackgroundColours = [
      'rgb(238, 49, 36)', // Red 54%
      'rgb(128, 128, 128)', // Black 50%
      'rgb(191, 191, 191)', // Black 75%
      'rgb(0, 0, 0)', // Black
      'rgb(143, 19, 10)', // Red 30%
      'rgb(51, 51, 51)', // Black 20%
      'rgb(89, 89, 89)', // Black 35%
      'rgb(246, 144, 136)', // Red 75%
    ]
    
    const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    })
    
    const getChartData = (chart: Record<string, any>) => {
        // Should always have at least one dataset
        const dataPoints = chart.values.map((dataPoint: any) => {
          return dataPoint.value
        });
        // But may have a second...
        const dataPoints2 = chart.values2 && chart.values2.map((dataPoint2: any) => {
          return dataPoint2.value
        });
        // Check if we have any data to display, we don't want an empty chart.
        const hasDataPoints = dataPoints.reduce((a: number, b: number) => a + b, 0) > 0 || (dataPoints2 && dataPoints2.reduce((a: number, b: number) => a + b, 0) > 0)
        let datasets = [{
          label: chart.legend,
          data: dataPoints,
          backgroundColor: segmentsBackgroundColours[0],
        }]
        if (dataPoints2) {
          datasets.push({
            label: chart.legend2,
            data: dataPoints2,
            backgroundColor: segmentsBackgroundColours[1],
          })
        }
        let chartElement
        switch (chart.chartType) {
          case 'Bar': {
            const barData = {
              labels: chart.values.map((dataPoint: any) => {
                return dataPoint.label
              }),
              datasets: datasets,
            }
            const plugins = [{
              id: 'custom_legend_plugin',
              legend: {
                display: true, //chart.legend && chart.legend.length > 0,
                onClick: () => {},
                labels: {
                  //padding: 50,
                  generateLabels: (chart: any) => {
                    chart.legend.afterFit = function() {
                      console.log('here')
                      this.height = this.height + 20
                    }
                    var data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                      return data.datasets.map((dataset: any, index: number) => {
                        return {
                          text: dataset.label,
                          fillStyle: dataset.backgroundColor,
                          hidden: !chart.isDatasetVisible(index),
                          lineCap: dataset.borderCapStyle,
                          lineDash: dataset.borderDash,
                          lineDashOffset: dataset.borderDashOffset,
                          lineJoin: dataset.borderJoinStyle,
                          lineWidth: dataset.borderWidth,
                          strokeStyle: dataset.borderColor,
                          pointStyle: dataset.pointStyle,
                          index: index,
                        }
                      })
                    }
                    return [];
                  }
                },
              },
            }]
            chartElement = (
              <Bar
                data={barData}
                options={resolveOptions(chart)}
                plugins={plugins}
              />
            )
            break
          }
          case 'Doughnut': {
            // This converts each data point to a percentage of the total, with a minimum of 1% (unless the value is
            // zero). This is so very small amounts that would normally be too small to be visible in the chart are 
            // still shown.
            const total = dataPoints.reduce((a: number, b: number) => a + b, 0)
            const dataPointsPercent = dataPoints.map((dataPoint: any) => {
              return dataPoint !== 0 ? Math.max(dataPoint / total * 100, 1) : 0
            });
            const doughnutData = {
              // Show the values alongside the label.
              labels: chart.values.map((dataPoint: any) => {
                if (chart.isCurrency === true) {
                  return `${dataPoint.label}: ${currencyFormatter.format(dataPoint.value)}`
                }
                return `${dataPoint.label}: ${dataPoint.value}`
              }),
              datasets: [
                {
                  label: chart.values.map((dataPoint: any) => {
                    return dataPoint.label;
                  }),
                  data: dataPointsPercent,
                  backgroundColor: segmentsBackgroundColours,
                },
              ],
            }
            chartElement = (
              <Doughnut
                data={doughnutData}
                options={resolveOptions(chart)}
              />
            )
            break
          }
          default:
            return null
        }
    
        return (
          <div className="w-1/3 flex flex-col px-4 mb-10 justify-between">
            <p className="text-center font-bold mb-4">
              {chart.title}
            </p>
            {chart.subTitle && (
              <p className="w-5/6 text-sm text-center leading-tight mx-auto mb-4">
                {chart.subTitle}
              </p>
            )}
            {hasDataPoints && (
              chartElement
            )}
            {!hasDataPoints && (
              <p className="flex justify-center items-center h-full">
                {emptyChartSubTitle}
              </p>
            )}
          </div>
        );
      };
    
      const resolveOptions = (chart: any) => {
        switch (chart.chartType) {
          case 'Bar': {
            let scaleOptions = {};
            if (chart.minValue || chart.maxValue) {
              scaleOptions = {
                scales: { 
                  y: { 
                    min: chart.minValue,
                    max: chart.maxValue,
                  }
                }
              };
            }

            // Bit hacky, but as there is no way to move the legend when we have multiple recordsets (and the datalabels are rotated and
            // may overflow the top of the chart and encroach on the legend) we need to create some space at the top of chart by making
            // the Y scale larger than the actual maximum value (by 15% in this case).
            let legendScaleOptions = {}
            if (chart.legend && chart.legend.length > 0) {
              const dataPoints = chart.values.map((dataPoint: any) => {
                return dataPoint.value
              });
              const maxValue = dataPoints.reduce((a: number, b: number) => Math.max(a, b), 0)
              legendScaleOptions = {
                scales: {
                  y: {
                    suggestedMax: Math.round(maxValue + ((maxValue / 100) * 15)),
                  },
                },
              }
              scaleOptions = {...scaleOptions, ...legendScaleOptions}
            }
            
            return {
              ...scaleOptions,
              layout: {
                padding: {
                  top: chart.legend && chart.legend.length > 0 ? 0 : 25,
                },
              },
              plugins: {
                legend: {
                  display: chart.legend && chart.legend.length > 0,
                },
                datalabels: {
                  display: true,
                  color: 'black' as const,
                  formatter: function(value: number) {
                    // Don't display "0"
                    return value > 0 ? value : ''
                  },
                  anchor: 'end' as const,
                  align: 'top' as const,
                  // Rotate the data labels if there is more than one dataset - this is so labels for larger values don't overlap
                  rotation: chart.values2 ? -90 : 0,
                  offset: 0,
                },
              },
            };
          }
          case 'Doughnut': {
            return {
              aspectRatio: 2,
              layout: {
                padding: {
                  top: 25,
                },
              },
              plugins: {
                legend: {
                  display: true,
                  position: 'right' as const,
                },
                datalabels: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: function(tooltipItem: any) {
                      return tooltipItem.label
                    },
                  },
                },
              },
            };
          }
          default: {
            return {}
          }
        }
      };

    return (
        <>
        <LoadingFeedback
            showSpinner={isLoading}
            translucentBackground={true}
        />
        {charts && charts.length > 0 && (
            <div className="w-full flex flex-wrap justify-evenly">
                {charts && charts.map((chart, index) => {
                    return (
                    <React.Fragment key={`chart-${index}`}>
                        {getChartData(chart)}
                    </React.Fragment>                                          
                    );
                })}
            </div>
        )}
        {statisticsUnavailable === true && (
            <p>{statisticsUnavailableMessage}</p>
        )}
        </>
    )
}