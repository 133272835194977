import React from "react"

interface IAlertMessageProps {
  mode?: string
  messages: string[]
  animate?: boolean
  show?: boolean
}

const AlertMessage = ({
  mode,
  messages,
  animate,
  show,
}: IAlertMessageProps) => {
  let modeClassName = "bg-gray-400"
  switch (mode) {
    case "success":
      modeClassName = "bg-green-100 border border-green-300"
      break
    case "warning":
      modeClassName = "bg-orange-200 text-horizonhover border border-orange-300"
      break
    case "danger":
      modeClassName = "bg-red-100 text-horizonhover border border-horizonred"
      break
    default:
      modeClassName = "bg-white text-horizonhover border border-gray-200"
  }
  return show ? (
    <div
      className={`flex items-center my-3 p-5 overflow-hidden font-normal ${modeClassName} ${
        animate ? "animate-pulse" : ""
      }`}
    >
      <svg
        className="w-8 h-8 mr-2 text-horizonhover mr-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        {mode === "danger" && (
          <path
            d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm0 319.91a20 20 0 1120-20 20 20 0 01-20 20zm21.72-201.15l-5.74 122a16 16 0 01-32 0l-5.74-121.94v-.05a21.74 21.74 0 1143.44 0z"
            fill="#ee3124"
            stroke="#ee3124"
          />
        )}
      </svg>
      <ul className="font-semibold text-xl">
        {messages.map((message: string) => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    </div>
  ) : null
}

export default AlertMessage
